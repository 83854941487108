import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { Area, IdName, MyHttpApi, OrderListUpdateRequest, Printer, PrivilegeItem, ProductCategory, ProductSubCategory, Station } from 'utils/api';
import { getSelectedCorporation, privilegeItemFromElement, privilegeToTypeAndName } from 'utils/corporation-util';
import { Notify } from 'utils/notify';


@autoinject
export class PosOrderListEdit {

	private printer: { [key: string]: boolean; } = {};
	private selectedArea: { [key: string]: boolean; } = {};
	private selectedStation: { [key: string]: boolean; } = {};
	private stationList: Station[] = [];
	private stationByAreaId: { [key: string]: Station[]; } = {};
	private printerList: Printer[] = [];
	private areaList: Area[] = [];
	private productCategoryList: ProductCategory[] = [];
	private productSubCategoryList: ProductSubCategory[] = [];
	private productSubCategoryListByProductCategoryId: { [key: string]: ProductSubCategory[]; } = {};
	private productCategory: { [key: string]: boolean; } = {};
	private productSubCategory: { [key: string]: boolean; } = {};
	private canEdit = false;
	private typeAndName = "";

	private privilege?: PrivilegeItem;
	orderListTemplate: IdName[] = [];
	orderListType: IdName[] = [];
	orderList: OrderListUpdateRequest = {
		type: "CLIENT",
		copies: 1,
		bell: true,
		seats: false,
		sortProductsByName: true,
		orderListType: "COURSES",
		orderListTemplate: "SERVICE_LONG",
		printerId: [],
		productCategoryId: [],
		productSubCategoryId: [],
		areaId: [],
		stationId: [],
		name: "",
		delete: false,
	};

	constructor(private api: MyHttpApi, private router: Router, private i18n: I18N, private notify: Notify) {
	}

	async activate(params: { id?: string, isMaster?: string; }) {
		if (!params.isMaster && !params.id) {
			this.privilege = getSelectedCorporation();
		} else if (params.isMaster) {
			this.privilege = undefined;
		}

		if (params.id) {
			let id = params.id;
			let tmp = await this.api.orderListByIdWithDependencies({ id });
			let orderList = {
				...tmp.orderList,
				delete: !!tmp.orderList.deleteTime,
				areaId: tmp.areaId,
				stationId: tmp.stationId,
				printerId: tmp.printerId,
				productCategoryId: tmp.productCategoryId,
				productSubCategoryId: tmp.productSubCategoryId,
			};
			this.orderList = { ...orderList, delete: !!orderList.deleteTime, type: 'CLIENT' };
			this.privilege = privilegeItemFromElement(orderList);
		}

		if (this.privilege) {
			this.typeAndName = await privilegeToTypeAndName(this.api, this.i18n, this.privilege);
			this.canEdit = await this.api.privilegeCanEdit(this.privilege);

			let [ productCategoryList, productSubCategoryList ] = await Promise.all([
				this.api.productCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
				this.api.productSubCategoryList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			]);

			this.productCategoryList = productCategoryList.filter(x => !x.deleteTime && !x.supersededById || this.orderList.productCategoryId.includes(x.id));
			this.productSubCategoryList = productSubCategoryList.filter(x => !x.deleteTime && !x.supersededById || this.orderList.productSubCategoryId.includes(x.id));

			for (let psc of this.productSubCategoryList) {
				if (!(psc.productCategoryId in this.productSubCategoryListByProductCategoryId)) {
					this.productSubCategoryListByProductCategoryId[psc.productCategoryId] = [];
				}
				this.productSubCategoryListByProductCategoryId[psc.productCategoryId].push(psc);
			}

			if (this.orderList.id) {
				for (let pc of this.orderList.productCategoryId) {
					this.productCategory[pc] = true;
				}
				for (let psc of this.orderList.productSubCategoryId) {
					this.productSubCategory[psc] = true;
				}
			}

			if (this.privilege.id && this.privilege.type === 'CLIENT') {
				let [bgr, printerList, areaList, stationList] = await Promise.all([
					this.api.businessGroupRestrictionsByClientId({ clientId: this.privilege.id }),
					this.api.printerList({ id: this.privilege?.id }),
					this.api.areaList({ id: this.privilege?.id }),
					this.api.stationList({ id: this.privilege?.id }),
				]);

				this.canEdit = bgr.clientSpecificOrderLists;

				this.stationByAreaId = {};
				for (let station of this.stationList) {
					if (!(station.areaId in this.stationByAreaId)) {
						this.stationByAreaId[station.areaId] = [];
					}
					this.stationByAreaId[station.areaId].push(station);
				}
				this.stationByAreaId = {};
	
				this.stationList = stationList.filter(x => !x.deleteTime);
	
				for (let station of this.stationList) {
					if (!(station.areaId in this.stationByAreaId)) {
						this.stationByAreaId[station.areaId] = [];
					}
					this.stationByAreaId[station.areaId].push(station);
				}
	
				this.printer = {};
				for (let pid of this.orderList.printerId) {
					this.printer[pid] = true;
				}
	
				this.selectedArea = {};
				for (let id of this.orderList.areaId) {
					this.selectedArea[id] = true;
				}
				this.selectedStation = {};
				for (let id of this.orderList.stationId) {
					this.selectedStation[id] = true;
				}
	
				this.printerList = printerList.filter(x => !x.deleteTime);
				this.areaList = areaList.filter(x => !x.deleteTime);
			}
	
		}

	}

	@computedFrom("privilege.type")
	get clientSpecific() {
		return this.privilege?.type === 'CLIENT';
	}

	productCategoryClicked(pc: ProductCategory) {
		if (this.productCategory[pc.id]) {
			for (let psc of this.productSubCategoryListByProductCategoryId[pc.id] || []) {
				if (this.productSubCategory[psc.id]) {
					this.productSubCategory[psc.id] = false;
				}
			}
		}
		return true;
	}

	productSubCategoryClicked(pc: ProductCategory, psc: ProductSubCategory) {
		if (!this.productSubCategory[psc.id]) {
			if (!this.productCategory[pc.id]) {
				this.productCategory[pc.id] = true;
			}
		}
		return true;
	}


	_buildRequest(deleted: boolean) {
		let productCategoryId = this.productCategoryList
			.filter(x => this.productCategory[x.id])
			.map(x => x.id);
		let productSubCategoryId = this.productSubCategoryList
			.filter(x => this.productSubCategory[x.id])
			.map(x => x.id);
		let printerId = this.printerList
			.filter(x => this.printer[x.id])
			.map(x => x.id);
		let areaId = this.areaList
			.filter(x => this.selectedArea[x.id])
			.map(x => x.id);
		let stationId = this.stationList
			.filter(x => this.selectedArea[x.areaId])
			.filter(x => this.selectedStation[x.id])
			.map(x => x.id);
		let obj: OrderListUpdateRequest = { ...this.orderList, areaId, printerId, stationId, productCategoryId: productCategoryId, productSubCategoryId: productSubCategoryId, delete: deleted };
		return obj;
	}


	async save(deleted: boolean) {
		let obj = this._buildRequest(deleted);

		if (!obj.productCategoryId.length && !deleted) {
			this.notify.info("orderList.noCategory", {})
			return;
		}

		await this.api.orderListUpdate({
			...obj,
			privilegeId: this.privilege?.id,
			type: this.privilege?.type || 'CLIENT',
			delete: deleted,
		});

		this.router.navigateBack();
	}
}
