import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { BusinessGroup, Client, MyHttpApi, OrderList, OrderListWithDependencies, PrivilegeItem } from 'utils/api';
import { getAllAccessLevelsViaClient, getSelectedClient, getSelectedCorporation } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

interface UIOrderList extends OrderListWithDependencies {
  deleted: boolean;
}

@autoinject
export class PosOrderListList {
	private fieldList: FieldSpec[] = [
		{ key: "name", header: "common.name", type: "text" },
		{ key: "printers", header: "common.printers", type: "text", },
		{ key: "copies", header: "common.copies", type: "number" },
		{ key: "areas", header: "common.areas", type: "text" },
		{ key: "productCategories", header: "common.productCategories", type: "text" },
		{ key: "productSubCategories", header: "common.productSubCategories", type: "text" },
		{ key: "deleted", header: "common.deleted", type: "boolean" },
	];

  private readonly defaults: { [key: string]: string; } = { "deleted": " " };
	private itemList: UIOrderList[] = [];
	private privilege?: PrivilegeItem;
	private clientId?: number;
	private isMaster = false;
	private clients: { [key: number]: Client; } = {};
	private businessGroups: { [key: number]: BusinessGroup; } = {};
 
	constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly notify: Notify, private readonly i18n: I18N) {
	}

	async activate(params: { isMaster: string; }) {
		let { clients, businessGroups, corporations } = await getAllAccessLevelsViaClient(this.api);
		this.clients = clients;
		this.businessGroups = businessGroups;
		this.isMaster = params.isMaster === "true";

		let [clientList] = await Promise.all([
			this.api.clientList(),
		]);

		for (let c of clientList) {
			clients[c.id] = { ...c, name: c.nickname };
		}
		this.clientId = getSelectedClient();
		if (!this.isMaster) {
			this.privilege = getSelectedCorporation();
			this.fieldList = [...this.fieldList,
				{ key: "corporationId", header: "corporation.corporation", type: "lookup", lookupData: corporations, lookupKey: "name", },
				{ key: "businessGroupId", header: "businessGroup.businessGroup", type: "lookup", lookupData: businessGroups, lookupKey: "name", },
				{ key: "clientId", header: "unit.unit", type: "lookup", lookupData: clients, lookupKey: "name", },
			];
		}
		await this.search();
	}

	async search() {
		if (!this.isMaster && !this.privilege) {
			return;
		}
		const itemList = await this.api.orderListListWithDependencies({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' });
    this.itemList = itemList.filter(x => !x.supersededById).map(x => ({...x, deleted: !!x.deleteTime}));
	}

	rowCall(key: string, row?: OrderList) {
		if (this.privilege?.type === 'CLIENT' && !row?.id && !this.isMaster) {
			const bgId = this.clients[this.privilege.id].businessGroupId || 0;
			if (bgId && !this.businessGroups[bgId].clientSpecificOrderLists) {
				this.notify.info("businessGroupRestriction.notAllowed", { element: this.i18n.tr(`businessGroupRestriction.orderList`) });
				return;
			}
		}
		this.router.navigateToRoute(this.editUrl, { id: row?.id, clientId: this.clientId });
	}

	@computedFrom("router.currentInstruction.config.navModel.config.name")
	get editUrl() {
		let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
		return routeName.replace(/-list$/, "-edit");
	}

	@computedFrom("privilege.id")
	get searchListener() {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		this.search();
		return "";
	}
}
