import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { LocalizedPaymentType, LocationUpdateRequest, MyHttpApi, Product } from 'utils/api';
import { clientIdToTypeAndName, getSelectedClient, privilegeItemFromElement } from 'utils/corporation-util';
import { Notify } from 'utils/notify';

interface UILocalizedPaymentType extends LocalizedPaymentType {
  checked: boolean;
}

@autoinject
export class PosLocationEdit {
  private location: LocationUpdateRequest = {
    customerName: "",
    customerBusinessId: "",
    name: "",
    streetAddress: "",
    zip: "",
    city: "",
    phone: "",
    email: "",
    paymentType: ['cash', 'rounding', 'credit', 'debit', 'amex', 'diners', 'bankcard', 'lunch', 'billing'],
    auditHour: 5,
    areaLimited: false,
    areaAccounting: false,
    doNotSendReports: false,
    sellPhysicalGiftCards: false,
    deleteOpenBillsInAutomatedAccounting: true,
    qrCodeScale: 6,
    type: "CLIENT",
    delete: false,
  };
  private clientId?: number;
  private typeAndName = "";
  private paymentTypeList: UILocalizedPaymentType[] = [];
  private productList: Product[] = [];
  private logo?: FileList;
  private logoBlob?: string;

  private extraGiftCardProduct?: Product;
  private extraExtraChargeProduct?: Product;
  private extraUnderChargeProduct?: Product;

  constructor(private api: MyHttpApi, private router: Router, private i18n: I18N, private notify: Notify) {
  }

  async activate(params: { id?: string, clientId?: number; }) {
    if (params.id) {
      let [location, logo] = await Promise.all([
        this.api.locationById({ id: params.id }),
        this.api.locationLogo({ locationId: params.id }),
      ]);
      this.location = { ...location, logo: logo?.imageData, delete: !!location.deleteTime, type: 'CLIENT' };
      this.logoBlob = logo?.imageData && "data:" + logo.imageMimeType + ";base64," + logo.imageData;

      this.clientId = privilegeItemFromElement(location)?.id;
    } else {
      this.clientId = getSelectedClient();
    }
    if (!this.clientId) {
      this.clientId = params.clientId;
    }

    this.typeAndName = await clientIdToTypeAndName(this.api, this.i18n, this.clientId);

    const [locationFullPaymentTypeList, productList] = await Promise.all([
      this.api.locationFullPaymentTypeList(),
      this.api.productList({ id: this.clientId, type: 'CLIENT' }),
    ]);
    this.extraGiftCardProduct = productList.find(x => x.id === this.location.giftCardSalesProductId);
    this.extraExtraChargeProduct = productList.find(x => x.id === this.location.extraChargeProductId);
    this.extraUnderChargeProduct = productList.find(x => x.id === this.location.underChargeProductId);
    this.paymentTypeList = locationFullPaymentTypeList.map(x => ({ ...x, checked: this.location.paymentType.indexOf(x.id) != -1 }));
    this.productList = productList.filter(x => !x.deleteTime && !x.supersededById);
  }

  async save(deleted: boolean) {
    if (!this.clientId) {
      return;
    }
    if (deleted && !confirm(this.i18n.tr("common.confirmDelete", {}))) {
      return;
    }
    await this.api.locationUpdate({
      ...this.location,
      paymentType: this.paymentTypeList.filter(x => x.checked).map(x => x.id),
      privilegeId: this.clientId,
      type: "CLIENT",
      delete: deleted,
    });
    this.router.navigateBack();
  }

  @computedFrom("logo")
  get logoObserver() {
    const location = this.location;
    if (!location) {
      return "";
    }

    const files = this.logo;
    if (!files) {
      return "";
    }

    const file = files[0];
    if (!file) {
      return "";
    }
    const reader = new FileReader();
    reader.onload = async () => {
      const res = reader.result;
      if (typeof res === "string") {
        const logoData = res.split(",", 2)[1];
        let logoIsOk = await this.api.imageTest({ image: logoData });
        if (!logoIsOk) {
          this.notify.info(this.i18n.tr("location.imageTestNotOk"));
          return "";
        }
        location.logo = logoData;
        this.logoBlob = res;
      }
    };
    reader.readAsDataURL(file);

    return "";
  }

  @computedFrom("location.areaLimited")
  get areaLimitedObserver() {
    if (this.location.areaLimited) {
      this.location.areaAccounting = true;
    }
    return "";
  }

  async clearImage() {
    if (this.location.id) {
      await this.api.locationDeleteLogo({ id: this.location.id });
    }
    this.location.logo = undefined;
    this.logoBlob = undefined;
  }
}
