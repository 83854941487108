import { autoinject, computedFrom } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { BusinessGroup, Client, MyHttpApi, OrderList, OrderMessage, OrderMessageGroup, OrderMessageGroupUpdateRequest, OrderMessageGroupWithPermission, OrderMessageUpdateRequest, Printer, PrivilegeItem, Product, ProductOrderMessageGroup, ProductOrderMessageGroupWithPermission } from 'utils/api';
import { getAllAccessLevelsViaClient, getSelectedClient, getSelectedCorporation, PosItemStub } from 'utils/corporation-util';
import { I18N } from 'aurelia-i18n';
import { Corporation } from '../utils/api';

class UIOrderMessage {
	constructor(public orderMessage: OrderMessage, public canEdit: boolean, public orderList: OrderList | undefined, public api: MyHttpApi) {
	}
	
	get availableInMessages() {
		return this.orderMessage.availableInMessages;
	}
	set availableInMessages(availableInMessages: boolean) {
		this.orderMessage.availableInMessages = availableInMessages;
		void this.api.orderMessageAvailableInMessagesUpdate({ id: this.orderMessage.id, availableInMessages });
	}
}

@autoinject
export class PosPrinterList {
	itemSpecificRequest: OrderMessageUpdateRequest = {
		type: "CLIENT",
		availableInMessages: false,
		itemSpecific: true,
		message: "",
		delete: false,
	};
	orderListSpecificRequest: OrderMessageUpdateRequest = {
		type: "CLIENT",
		availableInMessages: false,
		itemSpecific: false,
		message: "",
		delete: false,
	};

	private privilege?: PrivilegeItem;
	private clientId?: number;
	private isMaster = false;
	private clients: { [key: number]: Client; } = {};
	private businessGroups: { [key: number]: BusinessGroup; } = {};
	private corporations: { [key: number]: Corporation; } = {};

	private productList: Product[] = [];
	private productMap: { [key: string]: Product; } = {};
	private productOrderMessageGroupList: ProductOrderMessageGroupWithPermission[] = [];

	private orderListList: OrderList[] = [];
	private orderListMap: { [key: string]: OrderList; } = {};

	private orderMessageGroupList: OrderMessageGroupWithPermission[] = [];
	private orderMessageGroupMap: { [key: string]: OrderMessageGroup; } = {};
	private orderMessageList: UIOrderMessage[] = [];
	private orderMessageGroupRequest: OrderMessageGroupUpdateRequest = {
		type: "CLIENT",
		name: "",
		orderMessages: [],
		delete: false,
	};
	productOrderMessageGroupProduct?: Product;
	productOrderMessageGroupOrderMessageGroupId?: string;

	constructor(private api: MyHttpApi, private router: Router, private readonly i18n: I18N) {
	}

	async activate(params: { isMaster: string; }) {
		let { clients, businessGroups, corporations } = await getAllAccessLevelsViaClient(this.api);
		this.isMaster = params.isMaster === "true";
		this.clients = clients;
		this.businessGroups = businessGroups;
		this.corporations = corporations;
		if (!this.isMaster) {
			this.privilege = getSelectedCorporation();
		}
		await this.search();
	}

	getOwnerName(element: PosItemStub) {
		let name = "";
		if(element.clientId) {
			name = this.clients[element.clientId].nickname
		}
		if(element.businessGroupId) {
			name = this.businessGroups[element.businessGroupId].name
		}
		if(element.corporationId) {
			name = this.corporations[element.corporationId].name
		}
		return name;
	}

	// * Order Message Group
	async saveGroup(req: OrderMessageGroupUpdateRequest) {
		req = {...req, privilegeId: this.privilege?.id, type: this.privilege?.type || 'CLIENT'}
		await this.api.orderMessageGroupUpdate(req);
		this.orderMessageGroupRequest = {
			name: "",
			privilegeId: this.privilege?.id,
			type: this.privilege?.type || 'CLIENT',
			orderMessages: [],
			delete: false,
		};
		await this.search();
	}

	async removeOrderMessageGroup(omg: OrderMessageGroupUpdateRequest) {
		await this.api.orderMessageGroupUpdate({ ...omg, delete: true });
		this.orderMessageGroupList = this.orderMessageGroupList.filter(x => x.orderMessageGroupExtended.id !== omg.id);
		this.productOrderMessageGroupList = this.productOrderMessageGroupList.filter(pomg => pomg.productOrderMessageGroup.orderMessageGroupId !== omg.id);
	}

	async removeProductOrderMessageGroup(pomg: ProductOrderMessageGroup) {
		await this.api.productOrderMessageGroupUpdate({ ...pomg, privilegeId: this.privilege?.id, type: this.privilege?.type || 'CLIENT', delete: true });
		this.productOrderMessageGroupList = this.productOrderMessageGroupList.filter(x => x.productOrderMessageGroup !== pomg);
	}

	async deleteOrderMessage(row: UIOrderMessage) {
		await this.api.orderMessageUpdate({ ...row.orderMessage, privilegeId: this.privilege?.id, type: this.privilege?.type || 'CLIENT', delete: true });
		this.orderMessageList = this.orderMessageList.filter(i => i.orderMessage.id != row.orderMessage.id);
		await this.search();
	}


	@computedFrom("orderMessageGroupList.length")
	get dynamicOrderMessageGroup() {
		return this.orderMessageGroupList.map(omg => omg.orderMessageGroupExtended)
	}

	@computedFrom("orderMessageList.length")
	get bipolarOrderMessageList() {
		return this.orderMessageList.filter(x => !!x.orderMessage.orderListId && x.orderMessage.itemSpecific);
	}

	@computedFrom("orderMessageList.length")
	get itemSpecificOrderMessageList() {
		return this.orderMessageList.filter(x => x.orderMessage.itemSpecific);
	}

	@computedFrom("orderMessageList.length")
	get orderListSpecificOrderMessageList() {
		return this.orderMessageList.filter(x => !!x.orderMessage.orderListId);
	}

	@computedFrom("orderMessageList.length")
	get itemSpecificOrderMessageListForAssociate() {
		return this.orderMessageList.map(x => x.orderMessage).filter(x => x.itemSpecific);
	}

	// * Order message
	async saveOrderMessage(itemSpecific: boolean) {
		let obj = itemSpecific ? {...this.itemSpecificRequest, itemSpecific} : {...this.orderListSpecificRequest, itemSpecific};
		obj = {...obj, privilegeId: this.privilege?.id, type: this.privilege?.type || 'CLIENT'}
		let om = await this.api.orderMessageUpdate(obj);
		let orderMessage = new UIOrderMessage(om, true, om.orderListId != undefined ? this.orderListMap[om.orderListId] : undefined, this.api);
		this.orderMessageList.push(orderMessage);
		if (itemSpecific) {
			this.itemSpecificRequest = {
				privilegeId: this.privilege?.id,
				type: this.privilege?.type || 'CLIENT',
				availableInMessages: false,
				itemSpecific: true,
				message: "",
				delete: false,
			};
		} else {
			this.orderListSpecificRequest = {
				privilegeId: this.privilege?.id,
				type: this.privilege?.type || 'CLIENT',
				message: "",
				availableInMessages: false,
				itemSpecific: false,
				delete: false,
			}
		}
		await this.search();
	}

	async search() {
		if (!this.isMaster && !this.privilege) {
			return;
		}
		let [productList, orderListList, orderMessageGroupList, orderMessageListSS, productOrderMessageGroupList] = await Promise.all([
			this.api.productList({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.orderListListWithDependencies({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.orderMessageGroupListWithPermission({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.orderMessageListWithPermissions({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' }),
			this.api.productOrderMessageGroupListWithPermissions({ id: this.privilege?.id, type: this.privilege?.type || 'CLIENT' })
		]);

		this.orderListMap = MyHttpApi.toHashStr(orderListList);
		this.orderMessageGroupMap = MyHttpApi.toHashStr(this.orderMessageGroupList.map(omg => omg.orderMessageGroupExtended));
		this.productMap = MyHttpApi.toHashStr(productList);
		let orderMessageList = orderMessageListSS.filter(x => !x.orderMessage.open).map(x => new UIOrderMessage(x.orderMessage, x.canEdit, x.orderMessage.orderListId != undefined ? this.orderListMap[x.orderMessage.orderListId] : undefined, this.api));

		this.orderMessageGroupList = orderMessageGroupList.filter(x => !x.orderMessageGroupExtended.deleteTime && !x.orderMessageGroupExtended.supersededById);
		this.productList = productList.filter(x => !x.deleteTime);
		this.orderListList = orderListList.filter(x => !x.deleteTime && !x.supersededById);
		this.orderMessageList = orderMessageList.filter(x => !x.orderMessage.deleteTime && !x.orderMessage.supersededById).sort((a: UIOrderMessage, b: UIOrderMessage) => {
			const key1 = `${a.orderMessage.clientId || 0}${a.orderMessage.businessGroupId || 0}${a.orderMessage.corporationId || 0}${a.orderMessage.message}`
			const key2 = `${b.orderMessage.clientId || 0}${b.orderMessage.businessGroupId || 0}${b.orderMessage.corporationId || 0}${b.orderMessage.message}`
			return key1.localeCompare(key2);
		})
		this.productOrderMessageGroupList = productOrderMessageGroupList.filter(x => !x.productOrderMessageGroup.deleteTime).sort((a: ProductOrderMessageGroupWithPermission, b:ProductOrderMessageGroupWithPermission) => {
			const p1 = this.productList.find(p => p.id == a.productOrderMessageGroup.productId);
			const p2 = this.productList.find(p => p.id == b.productOrderMessageGroup.productId);
			const omg1 = this.orderMessageGroupMap[a.productOrderMessageGroup.orderMessageGroupId];
			const omg2 = this.orderMessageGroupMap[b.productOrderMessageGroup.orderMessageGroupId];
			const key1 = `${a.productOrderMessageGroup.clientId || 0}${a.productOrderMessageGroup.businessGroupId || 0}${a.productOrderMessageGroup.corporationId || 0}${p1?.name}${omg1?.name}`
			const key2 = `${b.productOrderMessageGroup.clientId || 0}${b.productOrderMessageGroup.businessGroupId || 0}${b.productOrderMessageGroup.corporationId || 0}${p2?.name}${omg2?.name}`
			return key1.localeCompare(key2);
		});
		this.clientId = getSelectedClient();
	}

	// * Product Order Message Group
	async assignOrderMessageGroupToProduct() {
		if (!this.productOrderMessageGroupProduct || !this.productOrderMessageGroupOrderMessageGroupId) {
			return;
		}

		let newproductGroup = await this.api.productOrderMessageGroupUpdate({
			privilegeId: this.privilege?.id,
			type: this.privilege?.type || 'CLIENT',
			productId: this.productOrderMessageGroupProduct.id,
			orderMessageGroupId: this.productOrderMessageGroupOrderMessageGroupId,
			delete: false,
		});
		this.productOrderMessageGroupProduct = undefined;
		this.productOrderMessageGroupOrderMessageGroupId = undefined;
		this.productOrderMessageGroupList.push({productOrderMessageGroup: newproductGroup, canEdit: true});
	}

	rowCall(key: string, row?: Printer) {
		this.router.navigateToRoute(this.editUrl, { id: row?.id, clientId: this.clientId });
	}

	@computedFrom("router.currentInstruction.config.navModel.config.name")
	get editUrl() {
		let routeName = this.router?.currentInstruction?.config?.navModel?.config?.name || "";
		return routeName.replace(/-list$/, "-edit");
	}

	@computedFrom("privilege.id")
	get searchListener() {
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		this.search();
		return "";
	}
}
